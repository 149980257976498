<template>
  <div>
    <div class="DivBox">
      <p class="PTitle">每年管理手册维护和更新</p>
    </div>
    <div class="DivBox">
      <p class="PTitle LeftBor">服务介绍：</p>
      <p class="SmallP">
        管理手册是企业一切制度文件的“宪法”，“宪法”如果不能适应新环境，就会成为企业发展的阻碍。
      </p>
      <br />

      <p class="PTitle LeftBor">解决客户的什么问题：</p>
      <p class="SmallP">
        管理手册只为满足体系认证要求，实际并无用处
        <br />
        内容啰嗦，员工阅读后无感，找不到工作的方法
        <br />
        管理手册与程序文件脱节
      </p>
      <br />
      <p class="PTitle LeftBor">我们公司的解决方案：</p>
      <p class="SmallP">
        培训管理手册编写技巧
        <br />
        协助建立手册大纲
        <br />
        辅导手册编写及检讨
      </p>
      <br />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
@import "../../../../assets/style/FirstProductSon.css";
</style>